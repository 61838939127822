export default {
  name: 'ZbCompaniesTabsComponent',
  data () {
    return {
      tab: null,
      items: {
        Perfil: { value: 0, icon: 'fas fa-file-alt' },
        'Perfil Premium de TikTok': { value: 0, icon: '$vuetify.icons.iconCertificate' },
        Descuentos: { value: 0, icon: 'fas fa-percent' },
        'Calendario de descuentos': { value: 0, icon: 'fas fa-calendar' },
        Integrantes: { value: 0, icon: 'fas fa-users' },
        Imágenes: { value: 0, icon: 'fas fa-images' },
        Horario: { value: 0, icon: 'fas fa-clock' },
        Ubicación: { value: 0, icon: 'fas fa-map-marker' },
        'Condiciones especiales': { value: 0, icon: 'fas fa-tasks' },
        Reservas: { value: 0, icon: 'fas fa-calendar-check' },
        Menú: { value: 0, icon: 'fas fa-th-list' },
        Estadísticas: { value: 0, icon: 'fas fa-chart-bar' }
      },
      componentKey: 0
    }
  },
  // watch: {
  //   tab (newVal, oldVal) {
  //     switch (newVal) {
  //       case 0:
  //         break
  //       case 1:
  //         break
  //       default:
  //         break
  //     }
  //   }
  // },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      // this.$router.push({ name: 'edit' })
      if (this.$route.params.id) {
        const id = this.$route.params.id
        // this.$router.push({ name: 'edit' })
        this.$router.push({ name: 'edit', params: { id } })
        // console.log(this.$route.params.id)
      } else {
        // console.log('no id')
      }
    },
    goToRouteFirstTab () {
      // console.log(this.$route.path, this.$route.params)
      if (this.$route.path !== '/admin/companies/companies-tabs/edit') {
        // console.log(this.$route.params.id)
        const id = this.$route.params.id
        this.$router.push({ path: 'edit' })
      }
    },
    forceRerender (key) {
      // this.componentKey += 1
      this.items[key].value += 1
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
